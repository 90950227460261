import { Dropzone, FileMosaic } from '@files-ui/react';
import { DevTool } from '@hookform/devtools';
import { Step, Stepper, Textarea, Typography } from '@material-tailwind/react';
import ImportLinksForm from 'components/forms/ImportLinksForm';
import DatePicker from 'components/inputs/DatePicker';
import Loading from 'components/loading/Loading';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ErrorHelper from 'helpers/ErrorHelper';
import { customSelectStyles } from 'options';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  FaAlignJustify,
  FaCheckCircle,
  FaEye,
  FaFileInvoice,
  FaLink,
} from 'react-icons/fa';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';

export default function CreateDeal() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);
  const [linkFiles, setLinkFiles] = useState([]);
  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const token = useStoreState((state) => state.auth.token);
  const [openAccordion, setOpenAccordion] = useState(1);
  const [category, setCategory] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [liveMonth, setLiveMonth] = useState(null);
  const [supplierOptions, setSupplierOptions] = useState(null);
  const [companyOptions, setCompanyOptions] = useState(null);
  const [date, setDate] = useState();

  const openModal = useStoreActions((actions) => actions.modal.openModal);

  const queryClient = useQueryClient();
  const {
    register,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm();

  const location = useLocation();

  // ask user for confirmation if they try to leave the page
  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      return (e.returnValue = 'Are you sure you want to leave the page?');
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, []);

  // check if user tries to navigate to another page using react-router
  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === '/admin/deals/create') {
      setActiveStep(0);
    }
  }, [location]);

  const handleNext = async (e) => {
    e.preventDefault();
    queryClient.invalidateQueries('deals');
    const validInputs = await validateInputs();
    if (!validInputs) {
      return;
    }
    return !isLastStep && setActiveStep((cur) => cur + 1);
  };
  const handlePrev = (e) => {
    e.preventDefault();
    return !isFirstStep && setActiveStep((cur) => cur - 1);
  };

  const handleOpenAccordion = (id) => {
    setOpenAccordion(id);
  };

  const validateInputs = async () => {
    if (activeStep === 0) {
      return await trigger(['supplier', 'company', 'notes']);
    }
    if (activeStep === 1) {
      return true;
    }
    if (activeStep === 2) {
      return true;
    }
  };

  const updateFiles = (incomingFiles) => {
    setLinkFiles(incomingFiles);
  };

  const navigate = useNavigate();

  const { data: suppliers, isLoading } = useQuery(
    'clients',
    async () => {
      const res = await ApiService.get(
        '/clients?per_page=100&fields[clients]=id,name',
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  );

  useEffect(() => {
    if (!suppliers) {
      return;
    }
    const _suppliers = suppliers.data.map((supplier) => {
      return {
        value: supplier.id,
        label: supplier.name,
      };
    });
    setSupplierOptions(_suppliers);
  }, [suppliers]);

  const { data: companies } = useQuery(
    'companies',
    async () => {
      const res = await ApiService.get(
        '/companies?per_page=100&fields[companies]=id,name',
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  );

  useEffect(() => {
    if (!companies) {
      return;
    }
    const _companies = companies.data.map((supplier) => {
      return {
        value: supplier.id,
        label: supplier.name,
      };
    });
    setCompanyOptions(_companies);
  }, [companies]);

  const changeActiveStep = (step) => {
    if (!getValues('supplier') || !getValues('company')) {
      return;
    }
    setActiveStep(step);
  };

  useEffect(() => {
    if (linkFiles.length > 0) {
      const toastId = toast.loading('Validating links...');
      // call /validate-import endpoint
      let formData = new FormData();
      formData.append('links_file', linkFiles[0]?.file);
      let config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/deals/validate-import',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + (token ?? null),
        },
        data: formData,
      };
      ApiService.request(config)
        .then((response) => {
          toast.update(toastId, {
            render: 'The data in the CSV file is valid',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            toast.dismiss();
            return openModal({
              name: 'bulk-import-error-modal',
              props: {
                message: ErrorHelper.handleValidationErrors(
                  error.response.data.errors,
                  toastId,
                ),
              },
            });
          }
          let message = ErrorHelper.handleApiError(
            error.response.status,
            toastId,
          );
          return toast.update(toastId, {
            render: message,
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        });
    }
  }, [linkFiles]);

  const onSubmit = () => {
    const data = getValues();
    const toastId = toast.loading('Creating deal...');
    let formData = new FormData();
    formData.append('client_id', data.supplier?.value);
    if (linkFiles.length > 0)
      formData.append('links_file', linkFiles[0]?.file ?? null);
    if (invoiceFiles.length > 0)
      formData.append('invoice_file', invoiceFiles[0]?.file ?? null);
    if (date) formData.append('invoice_due_date', date?.toISOString() ?? null);
    if (data?.sheet_url) {
      formData.append('sheet_url', data.sheet_url);
    }
    formData.append('company_id', data.company?.value);
    formData.append('live_month', liveMonth);
    if (data?.notes) formData.append('notes', data.notes);
    if (data?.message_for_finance)
      formData.append('message_for_finance', data.message_for_finance);
    if (category) formData.append('category', category);
    if (currency) formData.append('currency', currency);

    let config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/deals',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + (token ?? null),
      },
      data: formData,
    };
    ApiService.request(config)
      .then((response) => {
        queryClient.invalidateQueries(['deals']);
        toast.update(toastId, {
          render: 'Deal created successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        navigate('/admin/deals');
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.dismiss();
          return openModal({
            name: 'bulk-import-error-modal',
            props: {
              message: ErrorHelper.handleValidationErrors(
                error.response.data.errors,
                toastId,
              ),
            },
          });
        }
        let message = ErrorHelper.handleApiError(
          error.response.status,
          toastId,
        );
        return toast.update(toastId, {
          render: message,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div
        className='mt-4 flex min-h-[580px] flex-col justify-between rounded-lg bg-white px-24 py-8 shadow-lg dark:bg-navy-700 dark:text-white lg:w-8/12'
        name='create-deal'
      >
        <Stepper
          activeStep={activeStep}
          isLastStep={(value) => setIsLastStep(value)}
          isFirstStep={(value) => setIsFirstStep(value)}
        >
          <Step className='cursor-pointer' onClick={() => changeActiveStep(0)}>
            <FaAlignJustify className='h-5 w-5' />
            <div className='absolute -bottom-[2.5rem] w-max text-center'>
              <Typography
                color={activeStep === 0 ? 'indigo' : 'gray'}
                className='cursor-default select-none font-medium'
              >
                General Info
              </Typography>
            </div>
          </Step>
          <Step className='cursor-pointer' onClick={() => changeActiveStep(1)}>
            <FaLink className='h-5 w-5' />
            <div className='absolute -bottom-[2.5rem] w-max text-center'>
              <Typography
                color={activeStep === 1 ? 'indigo' : 'gray'}
                className='cursor-default select-none font-medium'
              >
                Link Upload
              </Typography>
            </div>
          </Step>
          <Step className='cursor-pointer' onClick={() => changeActiveStep(2)}>
            <FaFileInvoice className='h-5 w-5' />
            <div className='absolute -bottom-[2.5rem] w-max text-center'>
              <Typography
                color={activeStep === 2 ? 'indigo' : 'gray'}
                className='cursor-default select-none font-medium'
              >
                Invoice Upload
              </Typography>
            </div>
          </Step>
          <Step className='cursor-pointer' onClick={() => changeActiveStep(3)}>
            <FaEye className='h-5 w-5' />
            <div className='absolute -bottom-[2.5rem] w-max text-center'>
              <Typography
                color={activeStep === 3 ? 'indigo' : 'gray'}
                className='cursor-default select-none font-medium'
              >
                Overview
              </Typography>
            </div>
          </Step>
        </Stepper>
        <div className='mt-24'>
          <div
            className={`${activeStep === 0 ? 'block' : 'hidden'} transition`}
          >
            <div className='flex flex-col gap-6 lg:flex-row'>
              {suppliers && (
                <div className='w-1/2'>
                  <Controller
                    name='supplier'
                    control={control}
                    rules={{ required: 'Please select a supplier' }}
                    value={getValues('supplier')}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={supplierOptions}
                        isClearable
                        placeholder='Select a supplier...'
                        styles={customSelectStyles}
                        menuPosition='fixed'
                      />
                    )}
                  />
                  {errors.supplier && (
                    <span className='text-xs text-red-500'>
                      {errors.supplier.message}
                    </span>
                  )}
                </div>
              )}
              {companies && (
                <div className='w-1/2'>
                  <Controller
                    name='company'
                    control={control}
                    rules={{ required: 'Please select a company' }}
                    value={getValues('company')}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={companyOptions}
                        isClearable
                        placeholder='Select a company...'
                        styles={customSelectStyles}
                        menuPosition='fixed'
                      />
                    )}
                  />
                  {errors.company && (
                    <span className='text-xs text-red-500'>
                      {errors.company.message}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className='mt-6'>
              <Textarea
                variant='standard'
                color='indigo'
                label='Notes'
                className='dark:bg-transparent'
                {...register('notes')}
              />
            </div>
            <div className='mt-6'>
              <Textarea
                variant='standard'
                label='Message for finance'
                className='dark:bg-transparent'
                color='indigo'
                {...register('message_for_finance')}
              />
            </div>
          </div>
          <div
            className={`${activeStep === 1 ? 'block' : 'hidden'} transition`}
          >
            <ImportLinksForm
              openAccordion={openAccordion}
              handleOpenAccordion={handleOpenAccordion}
              register={register}
              errors={errors}
              setValue={setValue}
              control={control}
              linkFiles={linkFiles}
              updateFiles={setLinkFiles}
              onCategoryChange={(category) => {
                setCategory(category);
              }}
              onCurrencyChange={(currency) => {
                setCurrency(currency);
              }}
              onLiveMonthChange={(liveMonth) => {
                setLiveMonth(liveMonth);
              }}
            />
          </div>
          <div
            className={`${
              activeStep === 2 ? 'block' : 'hidden'
            } flex flex-col gap-4 transition`}
          >
            <DatePicker
              value={date}
              onChange={setDate}
              label='Select invoice due date...'
            />
            <div>
              <Dropzone
                color={'#6366f1'}
                onChange={(e) => setInvoiceFiles(e)}
                value={invoiceFiles}
                header={true}
                behaviour='replace'
                label='Upload Invoice'
                accept='application/pdf'
                className='bg-lightPrimary !font-dm'
              >
                {invoiceFiles.map((file, index) => (
                  <FileMosaic key={index} {...file} preview />
                ))}
              </Dropzone>
            </div>
          </div>
          {activeStep === 3 && (
            <div className='grid grid-cols-2 gap-4'>
              <div className='rounded-lg bg-lightPrimary px-5 py-2'>
                <p className='font-bold'>Supplier</p>
                <p className='text-gray-700'>
                  {
                    suppliers?.data?.find(
                      (s) => s.id === getValues('supplier').value,
                    ).name
                  }
                </p>
              </div>
              <div className='rounded-lg bg-lightPrimary px-5 py-2'>
                <p className='font-bold'>Company</p>
                <p className='text-gray-700'>
                  {
                    companies?.data?.find(
                      (company) => company.id === getValues('company').value,
                    ).name
                  }
                </p>
              </div>
              <div className='rounded-lg bg-lightPrimary px-5 py-2'>
                <p className='font-bold'>Notes</p>
                <p className='text-gray-700'>{getValues('notes')}</p>
              </div>
              <div className='rounded-lg bg-lightPrimary px-5 py-2'>
                <p className='font-bold'>Invoice Due Date</p>
                <p className='text-gray-700'>
                  {date?.toLocaleDateString() ?? null}
                </p>
              </div>
              <div className='rounded-lg bg-lightPrimary px-5 py-2'>
                <p className='font-bold'>Invoice File</p>
                <div className='flex items-center gap-2'>
                  <p className='text-gray-700'>Uploaded </p>
                  <FaCheckCircle className='text-green-500' />
                </div>
              </div>
              <div className='rounded-lg bg-lightPrimary px-5 py-2'>
                <p className='font-bold'>Links File</p>
                <div className='flex items-center gap-2'>
                  <p className='text-gray-700'>Uploaded</p>
                  <FaCheckCircle className='text-green-500' />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='mt-12 flex justify-between'>
          <button
            onClick={handlePrev}
            disabled={isFirstStep}
            className='w-24 rounded-lg bg-brand-500 px-4 py-2 text-white'
          >
            Previous
          </button>
          {isLastStep ? (
            <button
              // type='submit'
              onClick={() => onSubmit()}
              className='rounded-lg bg-gray-800 px-4 py-2 text-white'
            >
              Create Deal
            </button>
          ) : (
            <button
              onClick={handleNext}
              className='w-24 rounded-lg bg-brand-500 px-4 py-2 text-white'
            >
              Next
            </button>
          )}
        </div>
      </div>
      <DevTool control={control} />
    </>
  );
}
