import Card from 'components/card';
import BarChart from 'components/charts/BarChart';
import ChartDropdown from 'components/dropdown/ChartDropdown';
import { topClientsChartOptions } from 'variables/charts';
const TopClients = ({ data, total }) => {
  let chartOptions = { ...topClientsChartOptions };
  chartOptions.xaxis.categories = data.map((item) => item.name).reverse();
  chartOptions.xaxis.labels.show = true;
  return (
    <Card extra='pb-0 p-[20px]'>
      <div className='flex flex-row items-center justify-between'>
        <div className='ml-1 pt-2'>
          <div className='text-xl font-bold text-navy-700 dark:text-white'>
            Top Clients
          </div>
          <span className='text-sm font-medium text-gray-600'>
            All time • By number of deals
          </span>
        </div>
      </div>

      <div className='h-full min-h-[300px] w-full pb-0'>
        <BarChart
          chartData={[
            {
              name: 'Deals',
              data: data.map((item) => item.deal_count).reverse(),
            },
          ]}
          chartOptions={chartOptions}
        />
      </div>
    </Card>
  );
};

export default TopClients;
