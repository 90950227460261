import DateTimeHelper from 'helpers/DateTimeHelper';
import PriceHelper from 'helpers/PriceHelper';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';
const twConfig = resolveConfig(tailwindConfig);

export const barChartDataDailyTraffic = [
  {
    name: 'Daily Traffic',
    data: [20, 30, 40, 20, 45, 50, 30],
  },
];

export const barChartOptionsDailyTraffic = {
  chart: {
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  tooltip: {
    style: {
      fontSize: '12px',
      fontFamily: 'DM Sans',
      backgroundColor: '#000000',
    },
    onDatasetHover: {
      style: {
        fontSize: '12px',
        fontFamily: 'DM Sans',
      },
    },
    theme: 'dark',
  },
  xaxis: {
    categories: ['00', '04', '08', '12', '14', '16', '18'],
    show: false,
    labels: {
      show: true,
      style: {
        colors: '#A3AED0',
        fontSize: '10px',
        fontWeight: '500',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: 'black',
    labels: {
      show: true,
      style: {
        colors: '#CBD5E0',
        fontSize: '14px',
      },
    },
  },
  grid: {
    show: false,
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    colors: [
      twConfig.theme.colors.indigo[500],
      twConfig.theme.colors.lime[500],
      twConfig.theme.colors.pink[500],
    ],
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 6,
      columnWidth: '30px',
    },
  },
};

export const pieChartOptions = {
  labels: ['Your files', 'System', 'Empty'],
  colors: [
    twConfig.theme.colors.brand[600],
    twConfig.theme.colors.lime[600],
    twConfig.theme.colors.pink[600],
    twConfig.theme.colors.blue[600],
    twConfig.theme.colors.red[600],
    twConfig.theme.colors.green[600],
    twConfig.theme.colors.yellow[600],
    twConfig.theme.colors.teal[600],
  ],
  chart: {
    width: '50px',
    sparkline: {
      enabled: true,
    },
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: true,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: [
      twConfig.theme.colors.brand[500],
      twConfig.theme.colors.lime[500],
      twConfig.theme.colors.pink[500],
    ],
  },
  tooltip: {
    enabled: true,
    theme: 'dark',
    style: {
      fontSize: '12px',
      fontFamily: 'DM Sans',
      backgroundColor: '#000000',
    },
  },
};

export const barChartDataWeeklyRevenue = [
  {
    name: 'PRODUCT A',
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
    color: '#6AD2Fa',
  },
  {
    name: 'PRODUCT B',
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
    color: '#4318FF',
  },
  {
    name: 'PRODUCT C',
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
    color: '#EFF4FB',
  },
];

export const barChartOptionsWeeklyRevenue = {
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  // colors:['#ff3322','#faf']
  tooltip: {
    style: {
      fontSize: '12px',
      fontFamily: 'DM Sans',
      backgroundColor: '#000000',
    },
    theme: 'dark',
    onDatasetHover: {
      style: {
        fontSize: '12px',
        fontFamily: 'DM Sans',
      },
    },
  },
  xaxis: {
    categories: ['17', '18', '19', '20', '21', '22', '23', '24', '25'],
    show: false,
    labels: {
      show: true,
      style: {
        colors: '#A3AED0',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: 'black',
    labels: {
      show: false,
      style: {
        colors: '#A3AED0',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
  },

  grid: {
    borderColor: 'rgba(163, 174, 208, 0.3)',
    show: true,
    yaxis: {
      lines: {
        show: false,
        opacity: 0.5,
      },
    },
    row: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: 'solid',
    colors: ['#5E37FF', '#6AD2FF', '#E1E9F8'],
  },
  legend: {
    show: false,
  },
  colors: ['#5E37FF', '#6AD2FF', '#E1E9F8'],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: '20px',
    },
  },
};

export const lineChartDataTotalSpent = [
  {
    name: 'Revenue',
    data: [50, 64, 48, 66, 49, 68],
    color: '#4318FF',
  },
  {
    name: 'Profit',
    data: [30, 40, 24, 46, 20, 46],
    color: '#6AD2FF',
  },
];

const areaChartOptions = {
  chart: {
    height: '100%',
    type: 'area',
    fontFamily: 'DM Sans',
    foreColor: twConfig.theme.colors.gray[600],
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    show: true,
    borderColor: 'rgba(0, 0, 0, 0.05)',
  },
  stroke: {
    curve: 'smooth',
  },
  yaxis: {
    forceNiceScale: true,
    labels: {
      formatter: function (val) {
        return val;
      },
    },
  },
  xaxis: {
    type: 'datetime',
    categories: [],
  },
  tooltip: {
    theme: 'dark',
    x: {
      format: 'dd/MM/yy HH:mm',
    },
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
};

export const totalSpentChartOptions = {
  ...areaChartOptions,
  ...{
    chart: {
      ...areaChartOptions.chart,
      events: {
        beforeZoom: function (ctx) {
          // we need to clear the range as we only need it on the iniital load.
          ctx.w.config.xaxis.range = undefined;
        },
      },
    },
    yaxis: {
      ...areaChartOptions.yaxis,
      labels: {
        formatter: function (val) {
          return PriceHelper.formatNumberAsCurrency(val);
        },
      },
    },
    xaxis: {
      ...areaChartOptions.xaxis,
      type: 'datetime',
      categories: [],
      range:
        new Date().getTime() -
        DateTimeHelper.substractMonths(new Date(), 6).getTime(),
    },
    tooltip: {
      ...areaChartOptions.tooltip,
      y: {
        formatter: function (val) {
          return PriceHelper.formatNumberAsCurrency(val);
        },
      },
    },
  },
};

const barChartOptions = {
  chart: {
    fontFamily: 'DM Sans',
    type: 'bar',
    height: '100%',
    foreColor: twConfig.theme.colors.gray[600],
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
      borderRadius: 8,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    categories: [],
    labels: {
      formatter: function (val) {
        const maxLength = 12;
        const value =
          val.length > maxLength ? val.substring(0, maxLength) + '...' : val;
        return value;
      },
      style: {
        colors: [twConfig.theme.colors.gray[600]],
        fontWeight: 500,
        fontSize: '10px',
      },
    },
  },
  yaxis: {
    forceNiceScale: true,
    title: {
      text: 'Number of Links',
    },
  },
  fill: {
    opacity: 1,
  },
  grid: {
    show: true,
    borderColor: 'rgba(0, 0, 0, 0.05)',
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
    x: {
      formatter: function (val) {
        return val;
      },
    },
    theme: 'dark',
  },
  fill: {
    colors: [
      twConfig.theme.colors.gray[700],
      twConfig.theme.colors.lime[500],
      twConfig.theme.colors.pink[500],
    ],
  },
};

export const topWebsitesChartOptions = {
  ...barChartOptions,
  ...{
    fill: {
      colors: [twConfig.theme.colors.indigo[500]],
    },
  },
};

export const topClientsChartOptions = {
  ...barChartOptions,
  xaxis: {
    categories: [],
    labels: {
      ...barChartOptions.xaxis.labels,
    },
  },
  yaxis: {
    title: {
      text: 'Number of Deals',
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 6,
      columnWidth: '70%',
    },
  },
};

export const dealGrowthChartOptions = {
  ...areaChartOptions,
  chart: {
    ...areaChartOptions.chart,
    events: {
      beforeZoom: function (ctx) {
        // we need to clear the range as we only need it on the iniital load.
        ctx.w.config.xaxis.range = undefined;
      },
    },
  },
  xaxis: {
    ...areaChartOptions.xaxis,
    type: 'datetime',
    categories: [],
    range:
      new Date().getTime() -
      DateTimeHelper.substractMonths(new Date(), 6).getTime(),
  },
  yaxis: {
    ...areaChartOptions.yaxis,
  },
  colors: [twConfig.theme.colors.blue[500]],
  fill: {
    colors: [twConfig.theme.colors.blue[500]],
  },
};
