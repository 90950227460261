import {
  IconButton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialContent,
  SpeedDialHandler,
  Typography,
} from '@material-tailwind/react';
import DataTable from 'components/table/DataTable';
import { useStoreActions } from 'easy-peasy';
import { hasPermissionTo } from 'helpers/AuthHelper';
import DateTimeHelper from 'helpers/DateTimeHelper';
import { useEffect, useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import { FaSpider } from 'react-icons/fa';
import { IoMdCheckmark } from 'react-icons/io';
import { MdUpcoming } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useStore from 'store';

const Deals = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();

  const customCellRenderer = (data, header, modelId) => {
    if (header === 'status') {
      let color = 'bg-brand-500';
      if (data === 'processed') {
        color = 'bg-green-500';
      }
      if (data === 'upcoming') {
        color = 'bg-yellow-500';
      }
      return (
        <span className={`rounded px-2 py-1 text-xs text-white ${color}`}>
          {data}
        </span>
      );
    }
    return null;
  };

  const openModal = useStoreActions((actions) => actions.modal.openModal);

  return (
    <div>
      <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <div className={`col-span-6 transition`}>
          <DataTable
            columnsData={[
              {
                Header: 'id',
                accessor: 'id',
                sortable: true,
              },
              {
                Header: 'client',
                accessor: 'client_name',
                colSpan: 2,
                sortable: true,
              },
              {
                Header: 'creator',
                accessor: 'user_name',
                colSpan: 2,
                sortable: true,
              },
              {
                Header: 'avg. price',
                accessor: 'average_price',
              },
              {
                Header: 'final price',
                accessor: 'final_price',
              },
              {
                Header: 'status',
                accessor: 'status',
                sortable: true,
              },
              {
                Header: 'notes',
                accessor: 'notes',
                colSpan: 2,
                wrapText: false,
                sortable: true,
                popover: true,
              },
              {
                Header: 'created at',
                accessor: 'created_at',
                sortable: true,
              },
              {
                Header: 'actions',
              },
            ]}
            customCellRenderer={customCellRenderer}
            modelName='deal'
            request='deals'
            onEditClick={(id) => navigate(`/admin/deals/${id}/edit`)}
            deletable={hasPermissionTo('delete deals')}
            editable={hasPermissionTo('update deals')}
            onCreateButtonClick={
              hasPermissionTo('create deals')
                ? () => navigate('/admin/deals/create')
                : null
            }
            filters={{
              status,
            }}
            AdditionalActions={() => (
              <SpeedDial placement='right'>
                <SpeedDialHandler>
                  <div className='flex cursor-pointer flex-row items-center gap-2 rounded-full bg-gray-100 text-gray-700 dark:bg-gray-900'>
                    <IconButton
                      size='md'
                      className={`rounded-full ${
                        status === 'processed' && 'bg-green-500'
                      } ${status === 'upcoming' && 'bg-yellow-500'}  ${
                        status === 'indexed' && 'bg-brand-500'
                      }`}
                    >
                      <BsFilter className='h-5 w-5 text-gray-500 text-white transition-transform group-hover:rotate-45' />
                    </IconButton>
                    <Typography className='pr-3 text-sm'>
                      Filter by status
                    </Typography>
                  </div>
                </SpeedDialHandler>
                <SpeedDialContent className='flex-row dark:text-gray-300'>
                  <SpeedDialAction
                    onClick={() => {
                      navigate(`/admin/deals`);
                      status !== 'processed'
                        ? setStatus('processed')
                        : setStatus(null);
                    }}
                    className='h-16 w-16 dark:border-gray-900 dark:bg-gray-900'
                  >
                    <IoMdCheckmark className='h-5 w-5 text-green-500' />
                    <Typography
                      color='blue-gray'
                      className='text-xs font-normal'
                    >
                      Processed
                    </Typography>
                  </SpeedDialAction>
                  <SpeedDialAction
                    onClick={() => {
                      navigate(`/admin/deals`);
                      status !== 'upcoming'
                        ? setStatus('upcoming')
                        : setStatus(null);
                    }}
                    className='h-16 w-16 dark:border-gray-900 dark:bg-gray-900'
                  >
                    <MdUpcoming className='h-5 w-5 text-yellow-500' />
                    <Typography
                      color='blue-gray'
                      className='text-xs font-normal'
                    >
                      Upcoming
                    </Typography>
                  </SpeedDialAction>
                  <SpeedDialAction
                    onClick={() => {
                      navigate(`/admin/deals`);
                      status !== 'indexed'
                        ? setStatus('indexed')
                        : setStatus(null);
                    }}
                    className='h-16 w-16 dark:border-gray-900 dark:bg-gray-900'
                  >
                    <FaSpider className='h-5 w-5 text-brand-500' />
                    <Typography
                      color='blue-gray'
                      className='text-xs font-normal'
                    >
                      Indexed
                    </Typography>
                  </SpeedDialAction>
                </SpeedDialContent>
              </SpeedDial>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Deals;
