import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import Card from 'components/card';
import ClickAwayListener from 'react-click-away-listener';
import { IoMdWarning } from 'react-icons/io';
import { IoClose, IoWarning } from 'react-icons/io5';

const BulkImportErrorModal = ({
  onClose = null,
  onConfirm = null,
  message = null,
}) => {
  return (
    <>
      <Modal isOpen={true} onClose={onClose} className='!z-[1010]'>
        <ModalOverlay className='z-40 bg-gray-800/70 backdrop-blur-sm backdrop-brightness-50' />
        <ClickAwayListener onClickAway={onClose}>
          <ModalContent className='!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]'>
            <ModalBody>
              <Card extra='px-[30px] pt-[35px] pb-[40px]  flex flex-col !z-[1004] p-3 max-w-xl'>
                <div className='mb-[20px] flex items-center justify-between'>
                  <h1 className='flex items-center gap-2 text-2xl font-bold'>
                    <span>
                      <IoWarning className='text-red-500' />
                    </span>
                    Errors in bulk import
                  </h1>
                  <button
                    className='rounded-lg border text-2xl text-gray-500 transition hover:bg-gray-100'
                    onClick={onClose}
                  >
                    <IoClose />
                  </button>
                </div>
                <div className='max-h-[50vh] overflow-y-auto'>
                  <div className='mb-[20px] text-xs text-gray-800'>
                    {message
                      .replace(', ', '')
                      .split('\n')
                      .map((line, index) => (
                        <p className='mt-2' key={index}>
                          {line}
                        </p>
                      ))}
                  </div>
                </div>
              </Card>
            </ModalBody>
          </ModalContent>
        </ClickAwayListener>
      </Modal>
    </>
  );
};
export default BulkImportErrorModal;
