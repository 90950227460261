import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import {
  Timeline,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineItem,
} from '@material-tailwind/react';
import Card from 'components/card';
import DateTimeHelper from 'helpers/DateTimeHelper';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoCloseCircle } from 'react-icons/io5';
import { useQuery } from 'react-query';
import ApiService from 'services/ApiService';

export default function CrawlerModal({ linkId = null, onClose = null }) {
  const {
    data: crawls,
    isLoading,
    isError,
  } = useQuery(
    ['links', linkId, 'crawls'],
    async () => {
      // add start and end month if they exist
      const request = `links/${linkId}/crawls`;
      return await ApiService.get(request);
    },
    {
      enabled: linkId != null,
    },
  );
  if (isLoading) {
    return null;
  }
  return (
    <Modal isOpen={linkId} onClose={onClose} className='!z-[1010]'>
      <ModalOverlay className='z-40 bg-gray-800/70 backdrop-blur-sm backdrop-brightness-50 ' />
      <ModalContent className='!z-[1002] !m-auto !w-5/12 !max-w-[85%] md:top-[12vh]'>
        <ModalBody>
          <Card extra='px-[30px] pt-[35px] pb-[40px] min-w-[450px] flex flex-col gap-4 !z-[1004] min-h-[250px] justify-between'>
            <div>
              <h1 className='mb-[20px] text-2xl font-bold text-gray-800'>
                Crawls for link: {linkId}
              </h1>
              <div className='max-h-[50vh] overflow-y-scroll p-3'>
                <div className='mb-4 grid grid-cols-2 gap-4 text-gray-700'>
                  <div className='flex flex-col rounded-lg border p-3 shadow-lg'>
                    <span className='text-sm'>Url:</span>
                    <a
                      href={crawls?.data?.data[0]?.details?.url}
                      className='truncate whitespace-nowrap text-xs text-brand-500'
                    >
                      {crawls?.data?.data[0]?.details?.url}
                    </a>
                  </div>
                  <div className='flex flex-col rounded-lg border p-3 shadow-lg'>
                    <span className='text-sm'>Final Url:</span>
                    <a
                      href={crawls?.data?.data[0]?.details?.final_url}
                      className='truncate whitespace-nowrap text-xs text-brand-500'
                    >
                      {crawls?.data?.data[0]?.details?.final_url}
                    </a>
                  </div>
                </div>
                <Timeline>
                  {crawls?.data?.data?.map((crawl) => (
                    <TimelineItem className='mb-4'>
                      <TimelineConnector className='!w-[78px]' />
                      <TimelineHeader className='border-blue-gray-50 shadow-blue-gray-900/5 relative rounded-xl border bg-white py-2 pl-3 pr-6 shadow-lg'>
                        <TimelineIcon
                          className='p-3'
                          variant='ghost'
                          color={crawl.details.anchor_text ? 'green' : 'red'}
                        >
                          {crawl.details.anchor_text ? (
                            <IoIosCheckmarkCircle className='h-5 w-5 text-green-500' />
                          ) : (
                            <IoCloseCircle className='h-5 w-5 text-red-500' />
                          )}
                        </TimelineIcon>
                        <div className='flex flex-col gap-1 text-gray-700'>
                          <span className='text-sm font-semibold'>
                            {crawl.details.anchor_text ? (
                              crawl.details.anchor_text
                            ) : (
                              <span className='text-gray-700'>
                                Anchor text was not found
                              </span>
                            )}
                          </span>
                          <span className='text-sm font-normal text-gray-700'>
                            {DateTimeHelper.formatDate(crawl?.created_at)}
                          </span>
                        </div>
                      </TimelineHeader>
                    </TimelineItem>
                  ))}
                </Timeline>
              </div>
            </div>

            <div className='flex justify-end gap-2'>
              <button
                onClick={onClose}
                className='linear w-40 rounded-xl bg-lightPrimary px-5 py-2.5 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100'
              >
                Close
              </button>
            </div>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
