import DataTable from 'components/table/DataTable';
import DateTimeHelper from 'helpers/DateTimeHelper';
import { useState } from 'react';
import EditModal from './components/EditModal';

const Websites = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const customCellRenderer = (data, header, modelId) => {
    if (header === 'created at') {
      return <div>{DateTimeHelper.formatDate(data)}</div>;
    }
    if (header === 'units') {
      return (
        <div className='flex gap-1'>
          {data.map((unit) => {
            return (
              <div className='rounded-lg bg-indigo-100 px-2 py-1 text-xs text-indigo-500'>
                {unit?.name}
              </div>
            );
          })}
        </div>
      );
    }
    if (header === 'default market') {
      return <div>{data?.countryname}</div>;
    }
    if (header === 'url') {
      return (
        <a target='_blank' href={data} className='text-brand-500'>
          {data}
        </a>
      );
    }
    if (header === 'name') {
      return (
        <a target='_blank' href={modelId} className='text-brand-500'>
          {data}
        </a>
      );
    }
    return null;
  };

  return (
    <div>
      <EditModal
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
      <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <div className={`col-span-6 transition`}>
          <DataTable
            columnsData={[
              {
                Header: 'id',
                accessor: 'id',
                sortable: true,
              },
              {
                Header: 'name',
                accessor: 'name',
                sortable: true,
              },
              {
                Header: 'url',
                accessor: 'url',
                sortable: true,
                colSpan: 2,
              },
              {
                Header: 'default market',
                accessor: 'default_country',
                sortable: true,
              },
              {
                Header: 'units',
                accessor: 'units',
              },
              {
                Header: 'created at',
                accessor: 'created_at',
                sortable: true,
              },
              {
                Header: 'actions',
              },
            ]}
            customCellRenderer={customCellRenderer}
            modelName='website'
            request='websites'
            EditModal={EditModal}
            onCreateButtonClick={() => setShowCreateModal(true)}
            editable
            deletable
          />
        </div>
      </div>
    </div>
  );
};

export default Websites;
