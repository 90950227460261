import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from '@material-tailwind/react';
import CreateButton from 'components/buttons/CreateButton';
import Card from 'components/card';
import Checkbox from 'components/checkbox';
import DataTableDropdown from 'components/dropdown/DataTableDropdown';
import SearchInput from 'components/inputs/SearchInput';
import Loading from 'components/loading/Loading';
import { useStoreActions } from 'easy-peasy';
import { AnimatePresence, m } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { MdDelete, MdEdit } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useGlobalFilter, usePagination, useTable } from 'react-table';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';
import { useDebounce } from 'use-debounce';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

const DataTable = (props) => {
  const {
    columnsData,
    tableData,
    customCellRenderer,
    onPageChange,
    menu,
    onDeleteModels,
    modelName,
    request,
    deleteRequest = null,
    onSearch,
    EditModal = null,
    onEditClick = null,
    editable,
    deletable,
    filters = {},
    getData = null,
    onCreateButtonClick = null,
    AdditionalActions = null,
    extraClasses,
    MenuActions = null,
    name = null,
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);

  const [selectedModels, setSelectedModels] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [sortField = 'created at', setSortField] = useQueryParam(
    'sort_field',
    StringParam,
  );
  const [sortOrder, setSortOrder] = useState('desc');
  const [currentPage = 1, setCurrentPage] = useQueryParam('page', NumberParam);
  const [searchQuery, setSearchQuery] = useQueryParam('q', StringParam);
  const { id } = useParams();
  const [debouncedSearchQuery] = useDebounce(searchQuery, 600);

  useEffect(() => {
    if (!debouncedSearchQuery) {
      return;
    }
    setSelectedModels([]);
    setCurrentPage(1);
  }, [debouncedSearchQuery]);

  const {
    data: tableDataFromResource,
    isLoading,
    isFetching,
  } = useQuery(
    [
      request,
      { debouncedSearchQuery, currentPage, sortField, sortOrder, filters },
    ],
    async () => {
      const searchQuery = debouncedSearchQuery || '';

      const filterQueryString = Object.entries(filters)
        .filter(([key, value]) => value != null)
        .map(([key, value]) => ({ [key]: value }))
        .reduce((acc, obj) => ({ ...acc, ...obj }), {});

      let params = {
        page: currentPage,
        sort: `${sortOrder === 'desc' ? '-' : ''}${sortField}`,
      };
      if (searchQuery) {
        params.search = searchQuery;
      }
      if (filterQueryString) {
        params = { ...params, ...filterQueryString };
      }
      const response = await ApiService.get(request, { params });

      return response.data;
    },
    {
      enabled: !tableData,
      refetchOnMount:
        request === 'performance' || request === 'deals' ? 'always' : false,
    },
  );

  useEffect(() => {
    if (!tableDataFromResource) return;
    if (getData) {
      getData(tableDataFromResource);
    }
  }, [tableDataFromResource]);

  const data = useMemo(() => {
    if (tableDataFromResource) return tableDataFromResource.data;
    if (tableData) return tableData.data;
    return [];
  }, [tableDataFromResource, tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 200,
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    usePagination,
  );

  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    (ids) =>
      ApiService.post((deleteRequest ?? request) + '/bulk-delete', { ids }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          request,
          { debouncedSearchQuery, currentPage },
        ]);
      },
    },
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const selectModel = (id) => {
    setSelectedModels((prevSelectedModels) =>
      prevSelectedModels.includes(id)
        ? prevSelectedModels.filter((item) => item !== id)
        : [...prevSelectedModels, id],
    );
  };

  const selectAll = () => {
    setSelectedModels((prevSelectedModels) => [
      ...prevSelectedModels,
      ...(tableData
        ? tableData.data.map((item) => item.id)
        : tableDataFromResource.data.map((item) => item.id)),
    ]);
  };

  const deselectAll = () => {
    setSelectedModels([]);
  };

  const deleteModels = (ids = []) => {
    let modelsToDelete = [];
    if (ids.length > 0) {
      modelsToDelete = ids;
    } else {
      modelsToDelete = selectedModels;
    }
    if (onDeleteModels) {
      onDeleteModels(modelsToDelete);
    } else {
      toast.promise(deleteMutation.mutateAsync(modelsToDelete), {
        pending: 'Deleting items...',
        success: 'Items deleted successfully',
        error: 'Something went wrong! Item(s) could not be deleted.',
      });
    }
    setSelectedModel(null);
    setSelectedModels([]);
    closeModal();
  };

  const onPageChanged = (pageNumber) => {
    setCurrentPage(pageNumber.selected + 1);
  };

  const sortBy = (field) => {
    if (field === sortField) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === 'asc' ? 'desc' : 'asc',
      );
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const { openModal, closeModal } = useStoreActions((actions) => actions.modal);

  const showDeleteModal = (id = null) => {
    openModal({
      name: 'confirmation',
      props: {
        onConfirm: () => {
          deleteModels(id ? [id] : []);
          closeModal();
        },
        onClose: () => {
          closeModal();
        },
        title: 'Delete confirmation',
        message: `Are you sure you want to delete ${
          selectedModel || selectedModels.length <= 1
            ? `this ${modelName}`
            : 'these ' + selectedModels.length + ` ${modelName}s`
        }? This action is irreversible and will permanently remove all associated data and access rights for ${
          selectedModel || selectedModels.length <= 1
            ? `this ${modelName}`
            : `these ${modelName}s`
        }.`,
      },
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {EditModal && (
        <EditModal
          isOpen={showEditModal}
          onClose={() => {
            setShowEditModal(false);
            setSelectedModel(null);
          }}
          modelId={selectedModel}
        />
      )}

      <Card
        extra={`w-full h-full px-6 pb-6 sm:overflow-x-auto transition duration-100 ${
          extraClasses || ''
        } ${isFetching ? 'pointer-events-none' : ''}`}
      >
        <div className='relative flex items-center justify-between pt-4'>
          <div className='flex flex-col items-center gap-2 transition lg:flex-row'>
            <SearchInput
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              loading={isFetching}
            />
            {AdditionalActions && <AdditionalActions />}
          </div>
          <div className='flex items-center gap-x-2'>
            {onCreateButtonClick && (
              <CreateButton onClick={onCreateButtonClick} />
            )}
            {menu ? (
              menu
            ) : (
              <DataTableDropdown
                onDeleteSelected={() => {
                  if (selectedModels.length > 0) {
                    showDeleteModal();
                  }
                }}
                onSelectAll={selectAll}
                onDeselectAll={deselectAll}
                showDefaultOptions={deletable}
              >
                {MenuActions ? <MenuActions /> : null}
              </DataTableDropdown>
            )}
          </div>
        </div>

        <div className='mt-8 overflow-x-scroll xl:overflow-hidden'>
          <table {...getTableProps()} className={`w-full text-[12.9px]`}>
            <thead className={`border-b-2`}>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => {
                    if (column.render('Header') == 'actions') {
                      if (!(editable || deletable)) {
                        return null;
                      }
                    }
                    return (
                      <th
                        key={index}
                        scope='col'
                        colSpan={column.colSpan || 1}
                        className={`border-b border-gray-200 px-2 pb-[10px] text-right text-start dark:!border-navy-700 ${
                          column.render('Header') === 'actions' ? 'w-36' : ''
                        }`}
                      >
                        <button
                          className='flex items-center gap-x-1 text-xs tracking-wide text-gray-600'
                          disabled={!column?.sortable}
                          onClick={() => sortBy(column.Header)}
                        >
                          <span className='uppercase'>
                            {column.render('Header') == 'actions'
                              ? null
                              : column.render('Header')}
                          </span>
                          {column.Header === sortField && (
                            <FaArrowUp
                              className={`text-xs transition ${
                                sortOrder === 'desc' ? 'rotate-180' : ''
                              }`}
                            />
                          )}
                        </button>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <m.tr
                    layout
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    transition={{
                      duration: 0.2,
                      type: 'tween',
                      delay: index * 0.02,
                    }}
                    {...row.getRowProps()}
                    key={row.cells[0]?.value}
                    id={'#' + row.cells[0]?.value}
                    className={`${
                      selectedModels.includes(row.cells[0]?.value)
                        ? 'overflow-hidden bg-brand-200 text-brand-800 dark:bg-brand-900 dark:text-white'
                        : 'overflow-hidden odd:bg-lightPrimary dark:bg-navy-900 dark:odd:bg-navy-800'
                    }`}
                  >
                    {row.cells.map((cell, index) => {
                      let data = null;
                      if (customCellRenderer) {
                        data = customCellRenderer(
                          cell.value,
                          cell.column.Header,
                          cell.row.original.id,
                        );
                      }
                      if (!data) {
                        data = cell.value;
                      }
                      if (cell.column.Header === 'id') {
                        data = (
                          <div className='flex items-center gap-2 py-2'>
                            {deletable && (
                              <Checkbox
                                checked={selectedModels.includes(
                                  cell?.row?.original?.id,
                                )}
                                onChange={(e) =>
                                  selectModel(cell?.row?.original?.id)
                                }
                              />
                            )}
                            <p className='font-bold text-gray-700 dark:text-gray-300'>
                              {cell.value}
                            </p>
                          </div>
                        );
                      }
                      if (cell.column.Header === 'actions') {
                        if (!(editable || deletable)) {
                          return null;
                        }
                        if (editable || deletable) {
                          data = (
                            <>
                              <div className='flex flex-row justify-end gap-2'>
                                {editable && (
                                  <button
                                    onClick={() => {
                                      if (!onEditClick) {
                                        setSelectedModel(
                                          cell?.row?.original?.id,
                                        );
                                        setShowEditModal(true);
                                        return;
                                      }
                                      onEditClick(cell?.row?.original?.id);
                                    }}
                                    className='flex items-center gap-1 rounded bg-gray-700 px-1.5 py-0.5 text-white'
                                  >
                                    <MdEdit />
                                  </button>
                                )}
                                {deletable && (
                                  <button
                                    onClick={() => {
                                      showDeleteModal(cell?.row?.original?.id);
                                      // setSelectedModel(cell?.row?.original?.id);
                                    }}
                                    className='flex items-center gap-1 rounded bg-red-500 px-2 py-1 text-white'
                                  >
                                    <MdDelete />
                                  </button>
                                )}
                              </div>
                            </>
                          );
                        }
                      }
                      return (
                        <td
                          className={`w-[100px] cursor-default truncate whitespace-nowrap px-2 py-1 dark:text-gray-300`}
                          {...cell.getCellProps()}
                          key={index}
                          data-label={cell.column.Header}
                          colSpan={cell.column.colSpan || 1}
                        >
                          {cell.column.popover ? (
                            <Popover
                              placement='top-start'
                              animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0.6, y: 20 },
                              }}
                            >
                              <PopoverHandler>
                                <span className='select-none'>{data}</span>
                              </PopoverHandler>
                              <PopoverContent className='max-w-80'>
                                {data}
                              </PopoverContent>
                            </Popover>
                          ) : (
                            data
                          )}
                        </td>
                      );
                    })}
                  </m.tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {tableData?.meta ||
          (tableDataFromResource?.meta && (
            <div className='mt-3 flex items-center justify-between'>
              <div className='flex flex-row gap-x-4 text-sm text-gray-600'>
                <span>
                  Showing{' '}
                  {tableData?.meta?.from ?? tableDataFromResource?.meta?.from}{' '}
                  to {tableData?.meta?.to ?? tableDataFromResource?.meta?.to} of{' '}
                  {tableData?.meta?.total ?? tableDataFromResource?.meta?.total}
                </span>
                {selectedModels.length > 0 && (
                  <>
                    <span>|</span>
                    <span>{selectedModels.length} items selected</span>
                  </>
                )}
              </div>

              {(tableData?.meta || tableDataFromResource) && (
                <ReactPaginate
                  forcePage={currentPage - 1 || 0}
                  breakLabel='...'
                  nextLabel='next >'
                  onPageChange={onPageChanged}
                  // initialPage={currentPage}
                  pageRangeDisplayed={3}
                  pageCount={
                    tableData?.meta?.last_page ??
                    tableDataFromResource?.meta?.last_page
                  }
                  previousLabel='<'
                  nextLabel='>'
                  renderOnZeroPageCount={null}
                  containerClassName='select-none flex items-center -space-x-px py-2 px-3 text-sm justify-end text-sm'
                  pageLinkClassName='hidden md:flex select-none flex items-center justify-center px-1 py-2 px-3 leading-tight text-gray-700 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-900 dark:border-gray-900 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white'
                  breakLinkClassName='hidden md:flex select-none flex items-center justify-center px-1 py-2 px-3 leading-tight text-gray-700 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-900 dark:border-gray-900 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white'
                  previousLinkClassName='select-none rounded-l-lg flex items-center justify-center px-1 py-2 px-3 leading-tight text-gray-700 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-900 dark:border-gray-900 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white'
                  nextLinkClassName='select-none rounded-r-lg flex items-center justify-center px-1 py-2 px-3 leading-tight text-gray-700 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-900 dark:border-gray-900 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white'
                  activeLinkClassName='!bg-brand-500 !text-white pointer-events-none select-none z-10 flex items-center justify-center px-1 py-2 px-3 leading-tight text-white border border-brand-500 bg-brand-500 dark:border-gray-700 dark:bg-gray-700 hover:bg-brand-500 hover:text-white'
                  disabledClassName='opacity-50 pointer-events-none'
                />
              )}
            </div>
          ))}
      </Card>
    </>
  );
};

export default DataTable;
