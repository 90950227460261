import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Input } from '@material-tailwind/react';
import Card from 'components/card';
import ErrorHelper from 'helpers/ErrorHelper';
import { customSelectStyles, defaultToastOptions } from 'options';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';
const twConfig = resolveConfig(tailwindConfig);

const HelperText = ({ text }) => {
  return <p className='mt-1 text-xs text-red-500'>{text}</p>;
};

const EditModal = ({ isOpen = false, onClose = null, modelId = null }) => {
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const {
    data: formData,
    isFetching,
    isError: formDataIsError,
  } = useQuery(
    ['supplier-form', modelId],
    async () => {
      return await ApiService.get(
        `clients/edit${modelId ? '/' + modelId : ''}`,
      );
    },
    {
      enabled: isOpen,
      staleTime: modelId == null ? 120000 : 0,
      cacheTime: modelId == null ? 122000 : 0,
    },
  );

  useEffect(() => {
    if (!formData) {
      return;
    }
    if (modelId) {
      setValue('name', formData.data.client.name);
      setValue('vat', formData.data.client.vat);
      setValue('discount', formData.data.client.discount);
    }
    setCurrencies(
      formData.data.currencies.map((currency) => {
        return {
          value: currency.id,
          label: currency.name,
        };
      }),
    );

    setPaymentMethods(
      formData.data.payment_methods.map((payment) => {
        return {
          value: payment.id,
          label: payment.name,
        };
      }),
    );
  }, [formData]);

  useEffect(() => {
    if (!formData) {
      return;
    }
    if (!modelId) {
      return;
    }
    const label = currencies.find(
      (currency) => currency.value === formData.data.client.currency_id,
    )?.label;
    if (!label) return;
    setValue('currency', {
      value: formData.data.client.currency_id,
      label,
    });
  }, [currencies]);

  useEffect(() => {
    if (!formData) {
      return;
    }
    if (!modelId) {
      return;
    }
    const label = paymentMethods.find(
      (payment) => payment.value === formData.data.client.payment_id,
    )?.label;
    if (!label) return;
    setValue('payment_method', {
      value: formData.data.client.payment_id,
      label,
    });
  }, [paymentMethods]);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    clearErrors,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm();
  const queryClient = useQueryClient();

  const createSupplierMutation = useMutation(
    async (data) => await ApiService.post('/clients', data),
  );

  const updateSupplierMutation = useMutation(
    async (data) => await ApiService.put('/clients/' + modelId, data),
  );

  const onSubmit = () => {
    if (!modelId) {
      if (!isDirty) {
        return;
      }
    }
    if (Object.keys(errors).length > 0) {
      return;
    }
    const data = {
      name: getValues('name'),
      vat: getValues('vat'),
      currency_id: getValues('currency')['value'],
      payment_method_id: getValues('payment_method')['value'],
    };
    if (getValues('discount')) {
      data.discount = getValues('discount');
    }
    const toastId = toast.loading(
      `${modelId ? 'Updating' : 'Creating'} supplier...`,
    );
    if (modelId) {
      return updateSupplierMutation.mutate(data, {
        onSuccess: () => {
          queryClient.invalidateQueries('clients');
          reset();
          onClose();
          toast.update(toastId, {
            render: 'Supplier updated successfully',
            type: 'success',
            ...defaultToastOptions,
          });
        },
        onError: (error) => {
          if (error.response && error.response.status === 422) {
            return ErrorHelper.handleValidationErrors(
              error.response.data.errors,
              toastId,
            );
          }
          ErrorHelper.handleApiError(error.response.status, toastId);
        },
      });
    }
    createSupplierMutation.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
        reset();
        onClose();
        toast.update(toastId, {
          render: 'Supplier created successfully',
          type: 'success',
          ...defaultToastOptions,
        });
      },
      onError: (error) => {
        if (error.response && error.response.status === 422) {
          return ErrorHelper.handleValidationErrors(
            error.response.data.errors,
            toastId,
          );
        }
        ErrorHelper.handleApiError(error.response.status, toastId);
      },
    });
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen && !isFetching}
        onClose={() => {
          reset();
          onClose();
        }}
        className='!z-[1010]'
      >
        <ModalOverlay className='z-40 bg-gray-800/70 backdrop-blur-sm backdrop-brightness-50' />
        <ModalContent className='!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]'>
          <ModalBody>
            <Card extra='px-[30px] pt-[35px] pb-[40px] min-w-[450px] flex flex-col !z-[1004]'>
              <h1 className='mb-[30px] text-xl font-bold'>
                {modelId ? 'Edit supplier' : 'Create supplier'}
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-[20px] flex flex-col gap-y-6'>
                  <div>
                    <Input
                      className=''
                      variant='static'
                      placeholder='Enter name'
                      label='Name*'
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('name', {
                        required: true,
                        minLength: 3,
                        maxLength: 50,
                      })}
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <HelperText text='Name is required' />
                    )}
                    {errors.name && errors.name.type === 'minLength' && (
                      <HelperText text='Name must be at least 3 characters' />
                    )}
                  </div>
                  <div>
                    <Input
                      className=''
                      variant='static'
                      label='VAT*'
                      placeholder='Enter VAT'
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('vat', {
                        required: true,
                        minLength: 3,
                        maxLength: 50,
                      })}
                    />
                    {errors.vat && errors.vat.type === 'required' && (
                      <HelperText text='VAT is required' />
                    )}
                    {errors.vat && errors.vat.type === 'minLength' && (
                      <HelperText text='VAT must be at least 3 characters' />
                    )}
                    {errors.vat && errors.vat.type === 'validate' && (
                      <HelperText text={errors.vat.message} />
                    )}
                  </div>
                  <div>
                    <Input
                      className=''
                      variant='static'
                      label='Discount'
                      placeholder='Enter discount percentage'
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('discount', {
                        min: 0,
                        max: 100,
                      })}
                    />
                    {errors.url && errors.url.type === 'required' && (
                      <HelperText text='Url is required' />
                    )}
                  </div>
                  <div>
                    <Controller
                      name='currency'
                      control={control}
                      rules={{ required: 'Please select a currency' }}
                      value={getValues('currency')}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={currencies}
                          isClearable
                          placeholder='Select a currency...'
                          styles={customSelectStyles}
                        />
                      )}
                    />
                    {errors.currency && (
                      <HelperText text={errors.currency.message} />
                    )}
                  </div>
                  <div>
                    <Controller
                      name='payment_method'
                      control={control}
                      rules={{ required: 'Please select a payment method' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={paymentMethods}
                          isClearable
                          placeholder='Select a payment method...'
                          styles={customSelectStyles}
                        />
                      )}
                    />
                    {errors.payment_method && (
                      <HelperText text={errors.payment_method.message} />
                    )}
                  </div>
                </div>
                <div className='flex justify-end gap-2'>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      reset();
                      onClose();
                    }}
                    className='linear w-1/2 rounded-xl px-5 py-2.5 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='linear w-1/2 rounded-xl bg-indigo-500 px-5 py-2.5 text-base font-medium text-white transition duration-200 hover:bg-indigo-600'
                  >
                    {createSupplierMutation.isLoading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </form>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default EditModal;
