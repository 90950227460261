import { MenuItem } from '@material-tailwind/react';
import DataTable from 'components/table/DataTable';
import Widget from 'components/widget/Widget';
import DateTimeHelper from 'helpers/DateTimeHelper';
import PriceHelper from 'helpers/PriceHelper';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FaHandshake } from 'react-icons/fa';
import { IoDocumentOutline } from 'react-icons/io5';
import EditModal from './components/EditModal';
import ViewDealsModal from './components/ViewDealsModal';

const Performance = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [startMonth, setStartMonth] = useState(null);
  const [endMonth, setEndMonth] = useState(null);
  const [fromMonth, setFromMonth] = useState(null);
  const [toMonth, setToMonth] = useState(null);
  const [data, setData] = useState(null);
  const [viewDealsId, setViewDealsId] = useState(null);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    let websites = data?.data;
    if (!websites) return;
    websites = websites.map((_website) => {
      let website = {
        ..._website,
      };
      const pricesToFormat = [
        'final_price',
        'deal_links_sum_price',
        'deal_links_sum_discount_price',
        'deal_links_avg_price',
      ];
      pricesToFormat.forEach((price) => {
        website[price] = PriceHelper.formatNumberAsCurrency(
          website[price],
          'EUR',
        );
      });
      return website;
    });
    setCsvData(websites);
    setStartMonth(data?.metrics?.fromMonth);
    setEndMonth(data?.metrics?.toMonth);
  }, [data]);

  const customCellRenderer = (data, header, modelId) => {
    if (header === 'created at') {
      return <div>{DateTimeHelper.formatDate(data)}</div>;
    }
    if (['avg. price', 'final price', 'discount price'].includes(header)) {
      return <div>{PriceHelper.formatNumberAsCurrency(data)}</div>;
    }
    if (header === 'deals') {
      return (
        <button
          className='rounded bg-brand-500/10 px-2 py-1 text-xs text-indigo-600'
          onClick={() => setViewDealsId(modelId)}
        >
          View deals
        </button>
      );
    }
    return null;
  };

  const formatDateToYYYYMM = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${year}-${month}`;
  };

  const getDataFromDataTable = (data) => {
    setData(data);
  };

  return (
    <div>
      <ViewDealsModal
        websiteId={viewDealsId}
        onClose={() => setViewDealsId(null)}
        startMonth={startMonth}
        endMonth={endMonth}
      />
      {data && (
        <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-4'>
          <Widget
            icon={<FaHandshake className='h-6 w-6' />}
            title={'Final Price'}
            subtitle={data?.metrics?.finalPrice}
          />
          <Widget
            icon={<FaHandshake className='h-6 w-6' />}
            title={'Total Average Price'}
            subtitle={data?.metrics?.totalAveragePrice}
          />
          <Widget
            icon={<FaHandshake className='h-6 w-6' />}
            title={'Total Discount Price'}
            subtitle={data?.metrics?.totalDiscountPrice}
          />
          <Widget
            icon={<FaHandshake className='h-6 w-6' />}
            title={'Number of Links'}
            subtitle={data?.metrics?.linkCount}
          />
        </div>
      )}
      <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <div className={`col-span-6 transition`}>
          <DataTable
            columnsData={[
              {
                Header: 'id',
                accessor: 'id',
                sortable: true,
              },
              {
                Header: 'website',
                accessor: 'name',
                sortable: true,
                colSpan: 2,
              },
              {
                Header: 'links',
                accessor: 'deal_links_count',
                sortable: true,
              },
              {
                Header: 'discount price',
                accessor: 'deal_links_sum_discount_price',
                sortable: true,
              },
              {
                Header: 'avg. price',
                accessor: 'deal_links_avg_price',
                sortable: true,
              },
              {
                Header: 'final price',
                accessor: 'final_price',
                sortable: true,
              },
              {
                Header: 'deals',
                accessor: 'deals',
              },
            ]}
            customCellRenderer={customCellRenderer}
            modelName='performance'
            request='performance'
            editable={false}
            deletable={false}
            EditModal={EditModal}
            getData={getDataFromDataTable}
            filters={{
              start_date: fromMonth
                ? formatDateToYYYYMM(new Date(fromMonth))
                : null,
              end_date: toMonth ? formatDateToYYYYMM(new Date(toMonth)) : null,
            }}
            MenuActions={() => (
              <MenuItem className='flex flex-row items-center gap-2 hover:bg-gray-100/50'>
                <IoDocumentOutline />
                <CSVLink
                  data={csvData}
                  filename={
                    startMonth && endMonth
                      ? `performance-from-${startMonth}-to-${endMonth}.csv`
                      : 'performance.csv'
                  }
                >
                  <span>Export to CSV</span>
                </CSVLink>
              </MenuItem>
            )}
            AdditionalActions={() => (
              <div className='flex flex-row gap-2 lg:flex-row'>
                <div className='flex items-center gap-2'>
                  <span className='text-xs font-medium text-gray-700'>
                    From start of:
                  </span>
                  <input
                    className='w-48 rounded-lg border px-3 py-2 text-sm outline-0'
                    type='month'
                    id='start'
                    name='start'
                    min='2020-01'
                    onChange={(e) => setFromMonth(e.target.value)}
                    value={startMonth}
                  />
                </div>
                <div className='flex items-center gap-2'>
                  <span className='text-xs font-medium text-gray-700'>
                    To end of:
                  </span>
                  <input
                    className='w-48 rounded-lg border px-3 py-2 text-sm outline-0'
                    type='month'
                    id='start'
                    name='start'
                    min='2020-01'
                    onChange={(e) => setToMonth(e.target.value)}
                    value={endMonth}
                  />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Performance;
