import Loading from 'components/loading/Loading';
import DataTable from 'components/table/DataTable';
import PriceHelper from 'helpers/PriceHelper';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ApiService from 'services/ApiService';
import DealInfo from './components/DealInfo';
import EditForm from './components/EditForm';
import ImportLinksModal from './components/ImportLinksModal';
import LinkEditModal from './components/LinkEditModal';
import Widget from 'components/widget/Widget';
import { FaHandshake } from 'react-icons/fa';
import { MenuItem } from '@material-tailwind/react';
import { IoDocumentOutline } from 'react-icons/io5';

export default function EditDeal() {
  const { id } = useParams();
  const [importFormIsOpen, setImportFormIsOpen] = useState(false);
  const [linkCreateFormIsOpen, setLinkCreateFormIsOpen] = useState(false);
  const [dataTableData, setDatatableData] = useState(null);

  const queryConfig = {
    refetchOnWindowFocus: false,
    // staleTime: 1000 * 60 * 5,
  };
  const { data, isLoading } = useQuery(
    ['deals', id],
    async () => {
      const res = await ApiService.get(`/deals/${id}`);
      return res.data;
    },
    queryConfig,
  );

  const getDataFromDataTable = (data) => {
    setDatatableData(data);
  };

  useEffect(() => {
    if (!dataTableData) return;
    console.log(dataTableData);
  }, [dataTableData]);

  const { data: clients, isLoading: clientLoading } = useQuery(
    'clients',
    async () => {
      const res = await ApiService.get(
        '/clients?per_page=100&fields[clients]=id,name',
      );
      return res.data;
    },
    queryConfig,
  );

  const { data: companies, isLoading: companyLoading } = useQuery(
    'companies',
    async () => {
      const res = await ApiService.get(
        '/companies?per_page=100&fields[companies]=id,name',
      );
      return res.data;
    },
    queryConfig,
  );

  const customCellRenderer = (data, header, modelId) => {
    if (header === 'country') {
      return <div>{data.countryname}</div>;
    }
    if (header === 'target url' || header === 'referring site') {
      return (
        <a target='_blank' href={data} className='text-brand-500'>
          {data}
        </a>
      );
    }
    return null;
  };

  if ([clientLoading, companyLoading, isLoading].includes(true)) {
    return <Loading />;
  }

  return (
    <div className='mt-4 grid grid-cols-3 gap-6 3xl:grid-cols-10'>
      <LinkEditModal
        isOpen={linkCreateFormIsOpen}
        onClose={() => setLinkCreateFormIsOpen(false)}
      />
      {data.status != 'processed' ? (
        <EditForm deal={data} />
      ) : (
        <DealInfo deal={data} />
      )}
      <div className='col-span-10 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-4'>
        {dataTableData && (
          <>
            <Widget
              icon={<FaHandshake className='h-6 w-6' />}
              title={'Number of links'}
              subtitle={dataTableData?.links_count}
            />
            <Widget
              icon={<FaHandshake className='h-6 w-6' />}
              title={'Final price'}
              subtitle={dataTableData?.final_price}
            />
            <Widget
              icon={<FaHandshake className='h-6 w-6' />}
              title={'Average price'}
              subtitle={dataTableData?.total_links_average_cost}
            />
            <Widget
              icon={<FaHandshake className='h-6 w-6' />}
              title={'Total discount'}
              subtitle={dataTableData?.total_links_discount}
            />
          </>
        )}
      </div>
      <DataTable
        extraClasses='col-span-3 3xl:col-span-10'
        columnsData={[
          {
            Header: 'id',
            accessor: 'id',
            sortable: true,
          },
          {
            Header: 'referring site',
            accessor: 'referring_site',
            sortable: true,
            colSpan: 3,
          },
          {
            Header: 'website',
            accessor: 'website_name',
            colSpan: 2,
          },
          {
            Header: 'target url',
            accessor: 'target_url',
            sortable: true,
            colSpan: 3,
          },
          {
            Header: 'anchor text',
            accessor: 'anchor_text',
            sortable: true,
            colSpan: 2,
            popover: true,
          },
          {
            Header: 'price',
            accessor: 'price',
            sortable: true,
          },
          {
            Header: 'country',
            accessor: 'country',
            sortable: false,
          },
          {
            Header: 'created at',
            accessor: 'created_at',
            sortable: true,
          },
          {
            Header: 'actions',
            accessor: 'actions',
          },
        ]}
        deleteRequest='links'
        editable={data.status != 'processed'}
        deletable={data.status != 'processed'}
        modelName='link'
        EditModal={LinkEditModal}
        request={`deals/${id}/links`}
        customCellRenderer={customCellRenderer}
        name='deal-links'
        onCreateButtonClick={
          data.status != 'processed'
            ? () => setLinkCreateFormIsOpen(true)
            : null
        }
        AdditionalActions={() =>
          data.status != 'processed' && (
            <>
              <ImportLinksModal
                isOpen={importFormIsOpen}
                onClose={() => setImportFormIsOpen(false)}
              />
              <div className='flex gap-2'>
                <button
                  onClick={() => setImportFormIsOpen(true)}
                  className='rounded-lg bg-gray-700 px-3 py-2 text-white'
                >
                  Import Links
                </button>
              </div>
            </>
          )
        }
        MenuActions={() => (
          <MenuItem
            onClick={() => {}}
            className='flex flex-row items-center gap-2 hover:bg-gray-100/50'
          >
            <IoDocumentOutline />
            <span>Export to CSV</span>
          </MenuItem>
        )}
        getData={getDataFromDataTable}
      />
    </div>
  );
}
