import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import Card from 'components/card';

const ConfirmationModal = ({
  onClose = null,
  onConfirm = null,
  message = null,
  title = null,
}) => {
  return (
    <>
      <Modal isOpen={true} onClose={onClose} className='!z-[1010]'>
        <ModalOverlay className='z-40 bg-gray-800/70 backdrop-blur-sm backdrop-brightness-50' />
        <ModalContent className='!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]'>
          <ModalBody>
            <Card extra='px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]'>
              <h1 className='mb-[20px] text-2xl font-bold'>
                {title ? title : 'Are you sure?'}
              </h1>
              <div className='mb-[20px] opacity-70'>
                {message ? message : 'Are you sure you want to proceed?'}
              </div>
              <div className='flex gap-2'>
                <button
                  onClick={onClose}
                  className='linear w-full rounded-xl px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-600/5 active:bg-gray-700/5 dark:bg-gray-400/10 dark:text-white dark:hover:bg-gray-300/10 dark:active:bg-gray-200/10'
                >
                  Cancel
                </button>
                <button
                  onClick={onConfirm}
                  className='linear w-full rounded-xl bg-brand-500  px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-600 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30'
                >
                  Confirm
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ConfirmationModal;
