// ModalManager.js
import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import BulkImportErrorModal from 'components/modals/BulkImportErrorModal';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import AdvancedFiltersModal from 'components/modals/AdvancedFiltersModal';

const ModalManager = () => {
  const { name, props } = useStoreState((state) => state.modal);
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const renderModal = () => {
    switch (name) {
      case 'bulk-import-error-modal':
        return <BulkImportErrorModal {...props} onClose={closeModal} />;
      case 'confirmation':
        return <ConfirmationModal {...props} />;
      case 'advanced-filters':
        return <AdvancedFiltersModal {...props} onClose={closeModal} />;
      default:
        return null;
    }
  };

  return <>{renderModal()}</>;
};

export default ModalManager;
