import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { IoDocumentOutline } from 'react-icons/io5';
import { MenuItem } from '@material-tailwind/react';
import ApiService from 'services/ApiService';

const fetchCsv = async () => {
  const response = await ApiService.post(
    process.env.REACT_APP_API_URL + '/links/export',
    {
      responseType: 'blob',
      ids: [52032],
    },
  );
  return response.data;
};

const CsvExportButton = () => {
  const { refetch } = useQuery('downloadCsv', fetchCsv, {
    enabled: false,
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.csv'); // or any other file name
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    onError: (error) => {
      console.error('Error downloading the CSV file', error);
    },
  });

  return (
    <MenuItem
      onClick={() => refetch()}
      className='flex flex-row items-center gap-2 hover:bg-gray-100/50'
    >
      <IoDocumentOutline />
      <span>Export to CSV</span>
    </MenuItem>
  );
};

export default CsvExportButton;
